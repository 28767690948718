(function () {
    angular.module('informaApp')
        .directive('resizable', Resizable);

    function Resizable() {
        return {
            restrict: 'A',
            scope: {
                onResize: '=?',
                resizeOptions: '=?'
            },
            link(scope, element) {
                const defaultOptions = {
                    resize: (event, ui) => {
                        ui.position.left = ui.originalPosition.left;
                        ui.position.top = ui.originalPosition.top;

                        scope.onResize && scope.onResize(ui.size, ui.originalSize);
                    }
                };

                const getOptions = () => {
                    return {
                        ...defaultOptions,
                        ...(scope.resizeOptions || {})
                    };
                };

                element.resizable(getOptions());

                scope.$watch(scope.resizeOptions, () => {
                    element.resizable('option', getOptions());
                }, true);
            }
        }
    }
})();
